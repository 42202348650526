import React, { useState, useEffect } from 'react';
import { graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import '../sass/app.scss'

import { useBreakpoint } from "gatsby-plugin-breakpoints"

import bg from "../images/bg.jpg"
import bgmobile from "../images/bg-mobile.jpg"

const IndexPage = ({ data }) => {

  const breakpoints = useBreakpoint();
  var image;

  if (breakpoints.mobile) {
    image = bgmobile
  } else {
    image = bg
  }

  return (
    <Layout>
      <Seo title="Jocelyne Queirós" />
      <div className="fullWidth" style={{ backgroundColor: "#000" }}>
        <StaticImage
          src="../images/logo-preto.webp"
          loading="eager"
          className='display-none'
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt=""
          style={{}}
        />
        {breakpoints.md ? (
          <>
            <div className='div-top-mobile' style={{ backgroundImage: `url(${image})` }}>
              <div className="div-person-mobile" >
                <div className="div-info">
                  <div className="block margin-top-minus">
                    <div className='div-name'>
                      <p className="name yellow MBold" >
                        {data.globalJson.info.name[0]}
                      </p>&nbsp;
                      <p className="name yellow MRegular">
                        {data.globalJson.info.name[1]}
                      </p>
                    </div>
                    <p className="info white MMedium">
                      {data.globalJson.info.job}
                    </p>
                  </div>
                </div>
                <div className='div-tel-mobile'>
                  <a href={data.globalJson.mobile.contact.link} download="Jocelyne Queiros">
                    <GatsbyImage image={getImage(data.globalJson.mobile.contact.img)}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt={data.globalJson.mobile.contact.alt}
                      className="icon-tel-mobile"
                    />
                  </a>
                </div>
                <StaticImage
                  src="../images/person-mobile.png"
                  loading="eager"
                  className='img-person-mobile'
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt=""
                  style={{}}
                />
              </div>
            </div>
            <div className="div-social-mobile">
              {data.globalJson.mobile.social.map((social, l) => (
                <a href={social.link} target="_blank" key={"social" + l}>
                  <GatsbyImage image={getImage(social.img)}
                    quality={100}
                    formats={["auto", "webp", "avif"]}
                    alt={social.alt}
                  />
                </a>
              ))}

            </div>

            <div className='slogan-mobile'>
              <div className='div-title-slogan-mobile'>
                <p className='title-slogan-mobile MRegular yellow mb-15'>{data.globalJson.slogan.title[0]}</p>
                <p className='title-slogan-mobile MRegular yellow'>{data.globalJson.slogan.title[1]}</p>
              </div>
              <div className='div-title-slogan-mobile'>
                <p className='subtitle-slogan-mobile MMedium grey'>{data.globalJson.slogan.subtitle}</p>
              </div>
            </div>

            <div className="div-clique-mobile" style={{ backgroundColor: "#fff" }}>

              <StaticImage
                src="../images/clique.png"
                loading="eager"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Clique"
                className='icon-clique-mobile'
              />
            </div>
          </>)
          :
          (
            <>
              <div className='div-top' style={{ backgroundImage: `url(${image})` }}>
                <div className="div-info">
                  <div className='div-info-bottom'>
                    <div className='div-name'>
                      <p className="name yellow MBold" style={{ marginRight: "0.5vh" }}>
                        {data.globalJson.info.name[0]}
                      </p>&nbsp;
                      <p className="name yellow MRegular">
                        {data.globalJson.info.name[1]}
                      </p>
                    </div>
                    <p className="info white MMedium">
                      {data.globalJson.info.job}
                    </p>
                  </div>
                </div>
                <div className='div-email'>
                  <a href={data.globalJson.desktop.email.link}>
                    <StaticImage
                      src="../images/email-desk.png"
                      loading="eager"
                      quality={95}
                      formats={["auto", "webp", "avif"]}
                      alt=""
                      className='icon-email'
                    />
                  </a>
                </div>
                <StaticImage
                  src="../images/person-desk.png"
                  loading="eager"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt=""
                  className='img-person'
                />

              </div>
              <div className="div-social">
                {data.globalJson.desktop.social.map((social, l) => (
                  <a href={social.link} target="_blank" key={"social" + l}>
                    <GatsbyImage image={getImage(social.img)}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt={social.alt}
                    />
                  </a>
                ))}
              </div>

              <div className='slogan'>
                <div className='div-title-slogan'>
                  <p className='title-slogan MRegular yellow'>{data.globalJson.slogan.title[0]}</p>
                  <p className='title-slogan MRegular yellow'>{data.globalJson.slogan.title[1]}</p>
                </div>
                <div className='div-title-slogan'>
                  <p className='subtitle-slogan MMedium grey'>{data.globalJson.slogan.subtitle}</p>
                </div>
              </div>
              <div className="div-clique" style={{ backgroundColor: "#fff" }}>
                <StaticImage
                  src="../images/clique.png"
                  loading="eager"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt=""
                  style={{}}
                />
              </div>
            </>
          )}
      </div>
    </Layout>
  )
}

export default IndexPage


export const IndexQuery = graphql`
  query globalJson {
    globalJson {
      info{
        name
        job
      }
      mobile{
        tel{
          alt
          link
          img{
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        contact{
          alt
          link
          img{
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        social{
          alt
          link
          img{
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      desktop{
        email{
          alt
          link
          img{
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        social{
          alt
          link
          img{
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      slogan{
        title
        subtitle
      }
    }
  }
`
